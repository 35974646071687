import {createSlice, PayloadAction} from '@reduxjs/toolkit'

import {
    SizeDTO,
    IGamePageState,
    GameDTO,
    IPagination,
    IGameListItem, TournamentSettingsDTO, IEntriesListItem, IEntryItem, IFormulaItem, ITesterItem
} from "../utils/baseTypes";
import {formatDateTime} from "../utils/time";

export const initialState: IGamePageState = {
    isLoading: true,
    pagination: {
        currentPage: 1,
        pageSize: 50,
        total: 0,
    },
    games: [],
    gamesToDelete: [],
    updateListCnt: 0,
    entriesList: [],
    testersList: [],
    formulasList: [],
    selectedGame: undefined,
    tournamentConfig: undefined,

}

export const gameSlice = createSlice({
    name: 'game',
    initialState,
    reducers: {
        setLoading: (state, {payload}: PayloadAction<boolean>) => {
            state.isLoading = payload
        },
        setPagination: (state, {payload}: PayloadAction<IPagination>) => {
            state.pagination = payload
        },
        setGame: (state, {payload}: PayloadAction<GameDTO | undefined>) => {
            state.selectedGame = payload
        },
        setTournamentConfig: (state, {payload}: PayloadAction<TournamentSettingsDTO | undefined>) => {
            state.tournamentConfig = payload
            // @ts-ignore
            state.tournamentConfig = {...payload, ...{time: formatDateTime(payload.time)}};
            state.formulasList = []
            if(payload!.formulas) {
                Object.keys(payload!.formulas).forEach((key) => {
                    state.formulasList.push({
                        formula: payload!.formulas[key as keyof object],
                        bet: parseInt(payload!.bets[key as keyof object], 10),
                        award: parseInt(payload!.awards[key as keyof object], 10),
                    })
                })
            }
            if(payload!.entry_balances && payload!.entry_balances.length > 0){
                state.entriesList = []
                payload!.entry_balances.forEach((elem, index)=>{
                    state.entriesList.push({
                        entryBalance:parseInt(elem, 10),
                        entryFee:parseInt(payload!.entry_fees[index], 10),
                    })
                })
            }
            if(payload!.test_player_ids && payload!.test_player_ids.length > 0){
                state.testersList = []
                payload!.test_player_ids.forEach((elem, index)=>{
                    state.testersList.push({
                        id:elem,
                    })
                })
            }

        },
        setEntriesList: (state, {payload}: PayloadAction<IEntryItem[]>) => {
            state.entriesList = payload
        },
        setTestersList: (state, {payload}: PayloadAction<ITesterItem[]>) => {
            state.testersList = payload
        },
        setFormulasList: (state, {payload}: PayloadAction<IFormulaItem[]>) => {
            state.formulasList = payload
        },
        setGameList: (state, {payload}: PayloadAction<GameDTO[]>) => {
            state.games = []
            state.games = [...payload]
        },
        setGameListSize: (state, {payload}: PayloadAction<SizeDTO>) => {
            state.pagination.total = payload.size
        },
        setItemsToDelete: (state, {payload}: PayloadAction<IGameListItem[]>) => {
            state.gamesToDelete = payload
        },
        updateListCnt: (state) => {
            state.updateListCnt++
        },

    },
})


export const {
    setLoading,
    setPagination,
    setGameList,
    setGameListSize,
    setItemsToDelete,
    updateListCnt,
    setGame,
    setTournamentConfig,
    setEntriesList,
    setTestersList,
    setFormulasList,

} = gameSlice.actions
export default gameSlice.reducer
