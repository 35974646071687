import {createSlice, PayloadAction} from '@reduxjs/toolkit'

import {ISettingsGeneralPageState, RoleDTO, SettingsGeneralDTO} from "../utils/baseTypes";

export const initialState: ISettingsGeneralPageState = {
 isLoading: true,
    settings: undefined,
    roles: [],
    modules: [],
    selectedRole: undefined,
}

export const settingsGeneralSlice = createSlice({
    name: 'settingsGeneral',
    initialState,
    reducers: {
        setLoading: (state, {payload}: PayloadAction<boolean>) => {
            state.isLoading = payload
        },
        setSettings: (state, {payload}: PayloadAction<SettingsGeneralDTO>) => {
            state.settings = payload
        },
        setRoles: (state, {payload}: PayloadAction<RoleDTO[]>) => {
            state.roles = payload
        },
        setModules: (state, {payload}: PayloadAction<string[]>) => {
            state.modules = payload
        },
        setSelectedRole: (state, {payload}: PayloadAction<string>) => {
            if(state.roles && state.roles!.length > 0){
                state.selectedRole = state.roles?.find((elem) => {
                    return elem.type == payload;
                })}
        },
        setSelectedRoleModules: (state, {payload}: PayloadAction<string[]>) => {
            if(state.selectedRole){
                state.selectedRole.modules = payload
            }
        },

    },
})


export const {setLoading, setSettings, setRoles, setSelectedRole, setModules, setSelectedRoleModules} = settingsGeneralSlice.actions
export default settingsGeneralSlice.reducer