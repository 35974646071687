
import {combineReducers} from "@reduxjs/toolkit";
import globalSlice from './global.feature';
import authSlice from "./auth.feature";
import gameSlice from "./game.feature";
import transactionSlice from "./transaction.feature";
import playerSlice from "./player.feature";
import storeSlice from "./store.feature";
import userSlice from "./user.feature";
import settingsGeneralSlice from "./settings.general.feature";
import tagSlice from "./tag.feature";

const rootReducer = combineReducers({
    globalSlice,
    authSlice,
    gameSlice,
    tagSlice,
    transactionSlice,
    playerSlice,
    storeSlice,
    userSlice,

    settingsGeneralSlice
})
export default rootReducer
