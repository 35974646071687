import Constants from "../constants/constants";

export default {
    token: {
        fontFamily: "FontAwesome",
        fontSize: 16,
        controlHeight: 40,
        colorPrimary: '#4094ff',
        colorBgTextHover:  "#777777",
        colorTextBase: "black",
        colorBgContainer: "white",
        colorBgBase: "white",
        colorText:  "#494949",
        colorTextPlaceholder: "#777777",
        colorTextDisabled: "white",
        colorBgLayout:  "#494949",
        // colorInfoBg: "black",
        // colorInfoBgHover: "black",
        // colorPrimaryBg: "black",
        // colorPrimaryBgHover: "black",
        // colorSuccessBg: "black",
        // colorSuccessBgHover: "black",
        // colorWarningBg: "black",
        // colorWarningBgHover: "black",
        // boxShadow: "black",
        // boxShadowSecondary: "black",
        // colorBgContainerDisabled: "black",
        // colorBgTextActive: "black",
        // colorBorderBg: "black",
        // colorErrorOutline: "black",
        // colorFillAlter: "black",
        // colorFillContent: "black",
        // colorFillContentHover: "black",
        // colorHighlight: "black",
        // colorIcon: "black",
        // colorIconHover: "black",
        // colorLink: "black",
        // colorLinkActive: "black",
        // colorLinkHover: "black",
        // colorSplit: "black",
        // colorTextDescription: "black",
        // colorTextHeading: "black",
        // colorTextLightSolid: "black",
        // colorWarningOutline: "black",
        // controlItemBgActive: "black",
        // controlItemBgActiveDisabled: "black",
        // controlItemBgActiveHover: "black",
        // controlItemBgHover: "black",
        // controlOutline: "black",
        // colorBgElevated: "black",
        // colorBgMask: "black",
        // colorBgSpotlight: "black",
        // colorBorder: "black",
        // colorBorderSecondary: "black",
        // colorErrorActive: "black",
        // colorErrorBg: "black",
        // colorErrorBgHover: "black",
        // colorErrorBorder: "black",
        // colorErrorBorderHover: "black",
        // colorErrorHover: "black",
        // colorErrorText: "black",
        // colorErrorTextActive: "black",
        // colorErrorTextHover: "black",
        // colorFill: "black",
        // colorFillQuaternary: "black",
        // colorFillSecondary: "black",
        // colorFillTertiary: "black",
        // colorInfoActive: "black",
        // colorInfoBorder: "black",
        // colorInfoBorderHover: "black",
        // colorInfoHover: "black",
        // colorInfoText: "black",
        // colorInfoTextActive: "black",
        // colorInfoTextHover: "black",
        // colorPrimaryActive: "black",
        // colorPrimaryBorder: "black",
        // colorPrimaryBorderHover: "black",
        // colorPrimaryHover: "black",
        // colorPrimaryText: "black",
        // colorPrimaryTextActive: "black",
        // colorPrimaryTextHover: "black",
        // colorSuccessActive: "black",
        // colorSuccessBorder: "black",
        // colorSuccessBorderHover: "black",
        // colorSuccessHover: "black",
        // colorSuccessText: "black",
        // colorSuccessTextActive: "black",
        // colorSuccessTextHover: "black",
        // colorTextQuaternary: "black",
        // colorTextSecondary: "black",
        // colorTextTertiary: "black",
        // colorWarningActive: "black",
        // colorWarningBorder: "black",
        // colorWarningBorderHover: "black",
        // colorWarningHover: "black",
        // colorWarningText: "black",
        // colorWarningTextActive: "black",
        // colorWarningTextHover: "black",
    },
}
