import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import {
    SizeDTO,
    IPagination,
    IPlayerPageState,
    PlayerDTO
} from "../utils/baseTypes";

export const initialState: IPlayerPageState = {
 isLoading: true,
    pagination: {
        currentPage: 1,
        pageSize: 50,
        total: 0,
    },
    players: [],
    selectedPlayer: undefined
}

export const playerSlice = createSlice({
    name: 'player',
    initialState,
    reducers: {
        setLoading: (state, { payload }: PayloadAction<boolean>) => {
            state.isLoading = payload
        },
        setPagination: (state, { payload }: PayloadAction<IPagination>) => {
            state.pagination = payload
        },
        setPlayer: (state, { payload }: PayloadAction<PlayerDTO | undefined>) => {
            state.selectedPlayer = payload
        },
        setPlayerList: (state, { payload }: PayloadAction<PlayerDTO[]>) => {
            state.players = []
            state.players =  [...payload]
        },
        setPlayerListSize: (state, { payload }: PayloadAction<SizeDTO>) => {
            state.pagination.total = payload.size
        },

    },
})


export const { setLoading,setPagination, setPlayerList, setPlayerListSize, setPlayer} = playerSlice.actions
export default playerSlice.reducer
