import {createSlice, PayloadAction} from '@reduxjs/toolkit'

import {RootState} from "../store";
import {
    SizeDTO,
    IPagination,
    StoreDTO,
    IStorePageState, IStoreListItem, StoreSettingsDTO, RoleDTO, BucketDTO
} from "../utils/baseTypes";

export const initialState: IStorePageState = {
    isLoading: true,
    pagination: {
        currentPage: 1,
        pageSize: 10,
        total: 0,
    },
    stores: [],
    storesToDelete: [],
    selectedStore: undefined,
    selectedStoreSettings: undefined,
    updateListCnt: 0,
    selectedBucket: undefined,
    buckets: []

}

export const storeSlice = createSlice({
    name: 'store',
    initialState,
    reducers: {
        setLoading: (state, {payload}: PayloadAction<boolean>) => {
            state.isLoading = payload
        },
        setPagination: (state, {payload}: PayloadAction<IPagination>) => {
            state.pagination = payload
        },
        setStoreList: (state, {payload}: PayloadAction<StoreDTO[]>) => {
            state.stores = []
            state.stores = [...payload]
        },
        setStoreListSize: (state, {payload}: PayloadAction<SizeDTO>) => {
            state.pagination.total = payload.size
        },
        setItemsToDelete: (state, {payload}: PayloadAction<IStoreListItem[]>) => {
            state.storesToDelete = payload
        },
        updateListCnt: (state) => {
            state.updateListCnt++
        },
        setStore: (state, {payload}: PayloadAction<StoreDTO | undefined>) => {
            state.selectedStore = payload
        },
        setStoreSettings: (state, {payload}: PayloadAction<StoreSettingsDTO | undefined>) => {
            state.selectedStoreSettings = payload
        },
        setBuckets: (state, {payload}: PayloadAction<BucketDTO[]>) => {
            state.buckets = payload
        },
        setSelectedBucket: (state, {payload}: PayloadAction<string>) => {
            if (state.buckets && state.buckets!.length > 0) {
                state.selectedBucket = state.buckets?.find((elem) => {
                    return elem.id == payload;
                })
            }
        },
        removeSelectedBucket: (state) => {
            state.selectedBucket = undefined
        },
        setSelectedStore: (state, {payload}: PayloadAction<string>) => {
            if (state.stores && state.stores!.length > 0) {
                state.selectedStore = state.stores?.find((elem) => {
                    return elem.id == payload;
                })
            }
        },
        setSelectedBucketGames: (state, {payload}: PayloadAction<string[]>) => {
            if (state.selectedBucket) {
                state.selectedBucket.games = payload
            }
        },

    },
})


export const {
    setLoading,
    setPagination,
    setStoreList,
    setStoreListSize,
    setItemsToDelete,
    updateListCnt,
    setStore,
    setStoreSettings,
    setBuckets,
    setSelectedBucket,
    removeSelectedBucket,
    setSelectedBucketGames,
    setSelectedStore
} = storeSlice.actions
export default storeSlice.reducer
