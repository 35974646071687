import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import {IMessage, IAuthState, AuthDTO} from "../utils/baseTypes";
export const initialState: IAuthState = {
    isAuth: false,
    message: undefined,
}

export const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        setAuthSuccess: (state, { payload }: PayloadAction<AuthDTO>) => {
            state.currentUser = payload
            localStorage.setItem('isAuth', '1')
            state.isAuth = true
            state.message = undefined
            localStorage.setItem('user', JSON.stringify(payload))
            localStorage.setItem('token', payload.token)
        },
        setLogOut: (state) => {
            localStorage.setItem('isAuth', '0')
            state.isAuth = false
            state.currentUser = undefined
            localStorage.removeItem('user')
            localStorage.removeItem('token')
        },
        setAuthFailed: (state, { payload }: PayloadAction<IMessage>) => {
            state.message = payload
            localStorage.setItem('isAuth', '0')
            state.isAuth = false
        },
    },
})


export const { setAuthSuccess, setLogOut, setAuthFailed} = authSlice.actions
// export const authSelector = (state: RootState) => state
export default authSlice.reducer
