import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import {RootState} from "../store";
import {
    SizeDTO,
    IPagination,
    ITransactionListPageState,
    TransactionDTO
} from "../utils/baseTypes";

export const initialState: ITransactionListPageState = {
 isLoading: true,
    pagination: {
        currentPage: 1,
        pageSize: 50,
        total: 0,
    },
    transactions: []
}

export const transactionSlice = createSlice({
    name: 'transaction',
    initialState,
    reducers: {
        setLoading: (state, { payload }: PayloadAction<boolean>) => {
            state.isLoading = payload
        },
        setPagination: (state, { payload }: PayloadAction<IPagination>) => {
            state.pagination = payload
        },
        setTransactionList: (state, { payload }: PayloadAction<TransactionDTO[]>) => {
            state.transactions = []
            state.transactions =  [...payload]
        },
        setTransactionListSize: (state, { payload }: PayloadAction<SizeDTO>) => {
            state.pagination.total = payload.size
        },
    },
})


export const { setLoading,setPagination, setTransactionList, setTransactionListSize} = transactionSlice.actions
export default transactionSlice.reducer
