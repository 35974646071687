import { configureStore } from '@reduxjs/toolkit'
import logger from 'redux-logger'
import rootReducer from './features'
import {TypedUseSelectorHook, useDispatch, useSelector} from "react-redux";
import {ThunkAction, ThunkDispatch} from "redux-thunk";
import {AnyAction} from "redux";
import thunk from 'redux-thunk'

const changeState = {
    reducer: rootReducer,
    middleware: (getDefaultMiddleware: () => any) => [...getDefaultMiddleware(), logger, thunk], //todo logger for dev
    devTools: process.env.NODE_ENV !== 'production',
    // enhancers: [batchedSubscribe(debounceNotify)],
}


const store = configureStore(changeState)
export type RootState = ReturnType<typeof store.getState>
export default store

export type AppDispatch = typeof store.dispatch;
export type TypedDispatch = ThunkDispatch<RootState, any, AnyAction>;
export type TypedThunk<ReturnType = void> = ThunkAction<
    ReturnType,
    RootState,
    unknown,
    AnyAction
    >;
export const useTypedDispatch = () => useDispatch<TypedDispatch>();
export const useTypedSelector: TypedUseSelectorHook<RootState> = useSelector;

