import {createSlice, PayloadAction} from '@reduxjs/toolkit'
import {
    SizeDTO,
    IPagination,
    IUserPageState,
    UserDTO, IUserListItem, RoleDTO
} from "../utils/baseTypes";

export const initialState: IUserPageState = {
    isLoading: true,
    pagination: {
        currentPage: 1,
        pageSize: 50,
        total: 0,
    },
    users: [],
    selectedUser: undefined,
    usersToDelete: [],
    updateListTrigger: 0,

}

export const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        setLoading: (state, {payload}: PayloadAction<boolean>) => {
            state.isLoading = payload
        },
        setPagination: (state, {payload}: PayloadAction<IPagination>) => {
            state.pagination = payload
        },
        setUserList: (state, {payload}: PayloadAction<UserDTO[]>) => {
            state.users = []
            state.users =  [...payload]
        },
        setUserListSize: (state, {payload}: PayloadAction<SizeDTO>) => {
            state.pagination.total = payload.size
        },
        setUser: (state, {payload}: PayloadAction<UserDTO| undefined>) => {
            state.selectedUser = payload
        },
        setItemsToDelete: (state, {payload}: PayloadAction<IUserListItem[]>) => {
            state.usersToDelete = payload
        },
        updateListCnt: (state) => {
            state.updateListTrigger++
        },
    },
})


export const {
    setLoading,
    setPagination,
    setUserList,
    setUserListSize,
    setItemsToDelete,
    setUser,
    updateListCnt
} = userSlice.actions
export default userSlice.reducer
