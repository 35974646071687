
import Moment from 'moment';


export const formatTime = (date: string) => {
    return  Moment(date).format('yyyy DD MM hh:mm a');

}

export const formatDateTime = (date: string) => {
    return  Moment(date).format('yyyy-MM-DDTHH:MM');

}

export const formatDateTimeToSave = (date: string) => {
    return  Moment(date).toDate().getTime();
}

export const formatDateToTimestamp = (date: string) => {
    return  (Moment(date).toDate().getTime()/1000).toString();
}
