import React from 'react';
// import { useCookies } from 'react-cookie';

import loader from "../assets/images/loader.gif";
import {Spin} from "antd";
import {useSelector} from "react-redux";
import {RootState} from "../store";

type IAuthMiddleware = {
    children: React.ReactElement;
};

const GlobalRequestMiddleware: React.FC<IAuthMiddleware> = ({ children }) => {
    const setIsGlobalLoaderShow = useSelector((state: RootState) => state.globalSlice.isLoading)

    if (setIsGlobalLoaderShow) {
        return <Spin wrapperClassName="h-100" children={children} size={"default"} indicator={<img src={loader} alt="logo"/>}
                     className="text-bg-dark bg-transparent m-auto" tip="Please Wait..."/>;
    }

    return children;
};

export default GlobalRequestMiddleware;
