import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import {RootState} from "../store";
import {IGlobalPageStateBase} from "../utils/baseTypes";

export const initialState:IGlobalPageStateBase = {
    isSidebarCollapsed: false,
    isLoading: false,
}

export const globalSlice = createSlice({
    name: 'global',
    initialState,
    reducers: {
        setIsSidebarCollapsed: (state, {payload}: PayloadAction<boolean>) => {
            state.isSidebarCollapsed = payload
        },
        setIsGlobalLoaderShow: (state, {payload}: PayloadAction<boolean>) => {
            state.isLoading = payload
        },
    },
})


export default globalSlice.reducer
export const { setIsSidebarCollapsed, setIsGlobalLoaderShow} = globalSlice.actions
