import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {Provider} from 'react-redux';
import './theme/style.scss';
import store from './store';
import GlobalRequestMiddleware from "./middleware/GlobalRequestMiddleware";
import {CookiesProvider} from 'react-cookie';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
    <Provider store={store}>
        <CookiesProvider>
            <GlobalRequestMiddleware>
                <App/>
            </GlobalRequestMiddleware>
        </CookiesProvider>
    </Provider>
);
reportWebVitals();
