import {createSlice, PayloadAction} from '@reduxjs/toolkit'

import {
    SizeDTO,
    ITagPageState,
    TagDTO,
    IPagination,
    ITagListItem
} from "../utils/baseTypes";

export const initialState: ITagPageState = {
    isLoading: true,
    pagination: {
        currentPage: 1,
        pageSize: 50,
        total: 0,
    },
    tags: [],
    tagsToDelete: [],
    updateListCnt: 0,
    selectedTag: undefined,
}

export const tagSlice = createSlice({
    name: 'tag',
    initialState,
    reducers: {
        setLoading: (state, {payload}: PayloadAction<boolean>) => {
            state.isLoading = payload
        },
        setPagination: (state, {payload}: PayloadAction<IPagination>) => {
            state.pagination = payload
        },
        setTag: (state, {payload}: PayloadAction<TagDTO | undefined>) => {
            if(payload && !payload.games){
                payload.games = []
            }
            state.selectedTag = payload
        },
        setTagList: (state, {payload}: PayloadAction<TagDTO[]>) => {
            state.tags = []
            payload.forEach((elem)=> {
                if(!elem.games){
                    elem.games = []
                }
            })
            state.tags = [...payload]
        },
        setTagListSize: (state, {payload}: PayloadAction<SizeDTO>) => {
            state.pagination.total = payload.size
        },
        setItemsToDelete: (state, {payload}: PayloadAction<ITagListItem[]>) => {
            state.tagsToDelete = payload
        },
        updateListCnt: (state) => {
            state.updateListCnt++
        },

    },
})


export const {
    setLoading,
    setPagination,
    setTagList,
    setTagListSize,
    setItemsToDelete,
    updateListCnt,
    setTag,
} = tagSlice.actions
export default tagSlice.reducer
