import React, {Component, Suspense} from 'react';
import {HashRouter, Route, Routes, Navigate} from 'react-router-dom';
import './theme/style.scss';

const DefaultLayout = React.lazy(() => import('./layout/DefaultLayout'));
const Auth = React.lazy(() => import('./pages/auth/Auth'));
import {ConfigProvider, Spin} from "antd";
import loader from "./assets/images/loader.gif";
import BaseTheme from "./theme/__baseTheme";

class App extends Component {
    render() {
        return (
            <HashRouter>
                <ConfigProvider
                    theme={BaseTheme}
                >
                    <Suspense fallback={<Spin size={"default"} indicator={<img src={loader} alt="Loading"/>}
                                              className="text-bg-dark bg-transparent m-auto" tip="Loading..."/>}>
                        <Routes>
                            <Route path={'/auth' || '/'} element={<Auth/>}/>
                            <Route path="*" element={<DefaultLayout/>}/>
                        </Routes>
                    </Suspense>
                </ConfigProvider>

            </HashRouter>
        );
    }
}

export default App;
